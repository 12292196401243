import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: IExhibitorsDashboardTranslations = {
  title: 'Hallo',
  subtitle: 'Willkommen bei der beamian Event Management App',
  ticket_codes_title: 'Ticket codes',
  ticket_codes_subtitle: 'Copy and send these codes to whoever you want to invite!',
  usable_tickets: 'Unused tickets',
  general_information_title: 'Allgemeine Informationen',
  general_information_subtitle: 'Neueste Besucher-, Aussteller- und Interaktionszahlen',
  sessions_title: 'Your registration numbers',
  sessions_subtitle: 'Letzte Anzahl erstellter Sitzungen und Registrierungen',
  booked_as_favorite: 'Bookings as a favourite',
  upcoming_sessions: 'Nächste Sitzungen',
  exhibitor_pending_title: 'Aussteller muss noch genehmigt werden',
  exhibitor_pending_text:
    'Ihr Aussteller wurde nicht zum Betreten der Plattform zugelassen. Warten Sie, bis der Eventmanager Ihren Aussteller genehmigt hat.',
};

const tickets: IExhibitorTickets = {
  title: 'Zuteilungen',
  subtitle: 'Den Besuchern zugewiesene Eintrittskarten',
  new_assignment: 'Neue_Zuweisung',
  assignee_name: 'Name des Empfängers',
  assignee_email: 'E-Mail des Empfängers',
  visitor_name: 'Name des Besuchers',
  visitor_email: 'Email des Besuchers',
  ticket: 'Ticket',
  ticket_type_title: 'Fahrkarten',
  ticket_type_subtitle: 'Tickets an Besucher zuweisen',
  assign_title: 'Einem Besucher ein Ticket zuweisen',
  assign: 'Zuweisen',
  ticket_success: 'Ticket erfolgreich zugewiesen!',
};

const exports: IExhibitorExports = {
  title: 'Exporte',
  subtitle: 'Verwalten und Herunterladen Ihrer Exporte',
  export_contacts: 'Kontakte exportieren',
  export_contacts_subtitle:
    'Wählen Sie die Besucherinformationen aus, die in den Export aufgenommen werden sollen:',
};

const visitors: IExhibitorVisitors = {
  title: 'Visitors',
  subtitle: 'See visitor information, invite visitor to private sessions',
  assign_packages: 'Assign packages',
  visitor_information: 'Visitor information',
  assign_step_1: 'Find an existing visitor or register a new one',
  assign_step_2: 'Register new visitor',
  assign_step_3: 'Add packages to visitor',
  assign_step_4: 'All done!',
  assign_step_5: "You can't assign any more packages",
  out_of_packages: 'You have no packages to assign.',
  package_name: 'Package name',
  package_assigned_successfuly: 'Package assigned successfuly!',
  assign_another: 'Assign another',
  invite_private_session: 'Zu einer privaten Sitzung einladen',
  user_not_registered: 'This user is not registered for any sessions of your exhibitor.',
  enter_note_placeholder: 'Geben Sie Ihre Notiz ein',
  empty_list_title: 'Hier werden Ihre Kontakte angezeigt!',
  empty_list_subtitle: ' Teilen Sie Ihre Ausstellerseite, um mehr Interaktionen zu erhalten!',
  filters: {
    registered_for_sessions: 'Für Sitzungen angemeldet',
    interacted_with_exhibitor: 'Mit dem Aussteller interagiert',
    interacted_with_product: 'Mit dem Produkt interagiert',
    invited_by_you: 'Eingeladen von Ihnen',
    redeemed_code: 'Einen Ihrer Codes eingelöst',
  },
  sessions: {
    export: 'Exportieren',
    export_sessions: 'Sitzungen exportieren',
    message_201: 'Erstellung der Exportdatei gestartet. Bitte versuchen Sie es gleich noch einmal',
    message_204: 'Die Exportdatei wird gerade erstellt. Bitte versuchen Sie es gleich noch einmal',
    subtitle:
      'Wählen Sie zusätzliche Besucherinformationen aus, die in den Export aufgenommen werden sollen:',
  },
};

const sidebar: IExhibitorSidebar = {
  title: 'Ausstellen',
  home: 'Home',
  exhibitor_interactions: 'An dem Aussteller interessiert',
  exhibitor_sessions: 'Mein Kalender',
  interactions: 'Besucher',
  sessions: 'Kalender',
  settings: 'Profil',
  products: 'Angebote',
  product_interactions: 'An Angeboten interessiert',
  tickets: 'Bilhetes',
  exports: 'Exports',
  team: 'Team',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Ausstellereinstellungen',
  basicInfo: 'Basisinformation',
  save: 'Speichern',
  additionalInfo: 'Zusätzliche Information',
  formError: 'Keine Informationen abgerufen, Formular nicht verfügbar.',
};

const products: IExhibitorProducsTranslations = {
  title: 'Angebote',
  subtitle: 'Angebote erstellen, bearbeiten und löschen',
  no_products: 'Keine Angebote verfügbar',
  new_product: 'Neues Angebot',
  create_new_product: 'Erstellen',
  edit_product: 'Angebot bearbeiten',
  delete_confirm: 'Sind Sie sicher, dass Sie dieses Angebot löschen möchten?',
  interactions_exist_cannot_delete: 'Es gibt bereits Interaktionen. Kann nicht gelöscht werden.',
  search_placeholder: ' Geben Sie ein, wonach Sie in der Angebotsliste suchen möchten',
  you_have: 'You have',
  products_left: 'products left.',
  inputs: {
    name: 'Name',
  },
  form_not_ready:
    'Das Produktformular wurde von Ihrem Eventmanager noch nicht erstellt. Bitte versuchen Sie es später noch einmal.',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Besucher, die sich für den Aussteller interessieren',
  subtitle: 'Liste der Besucher, die sich für den Aussteller interessieren, überprüfen',
  download: 'Herunterladen',
  noInteractions: 'Dieser Aussteller hat keine Interaktionen.',
  interaction: {
    editComment: 'Kommentar bearbeiten',
    saveComment: 'Kommentar speichern',
    checkIn: 'Check-In',
  },
  search_placeholder: 'Geben Sie ein, wonach Sie in der Kontaktliste suchen möchten',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Authentifizierung',
  step_basicInfo: 'Basisinformation',
  step_additionalInfo: 'Zusätzliche Information',
  step_review: 'Überprüfen',
  step_done: 'Fertig!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Profilbild',
    embedded: 'Youtube-Video',
    meeting_room: 'Virtuellen Meeting-Room freigeben',
  },
  login: {
    title: 'Sie sind dabei, Ihren Aussteller anzumelden',
    subtitle: 'Erstlogin oder Anmeldung',
    message:
      'Um Ihren Aussteller anzumelden, müssen Sie sich einloggen oder ein neues beamian-Konto anlegen.',
    exhibitor_manager_name: 'Geben Sie den Namen des Verantwortlichen des Ausstellers ein',
    exhibitor_manager_email: 'Geben Sie die E-Mail des Verantwortlichen des Ausstellers ein',
  },
  basicInformation: {
    title: 'Erzählen Sie uns von Ihrem Aussteller.',
    warning:
      'Die von Ihnen angegebenen Informationen werden zur Erstellung der Broschüre sowie zur Erstellung eines Profils verwendet, das die Besucher sehen können.',
    createExhibitor: 'Aussteller erstellen',
    exhibitor_name: 'Ausstellername',
    exhibitor_name_placeholder: 'Geben Sie den Namen des Ausstellers ein (Firma)',
    modal: {
      title: 'Warnung',
      subtitle: 'Wenn Sie Ihren Aussteller verwalten möchten, melden Sie sich bitte zuerst an.',
      skip: 'Überspringen',
      createAccount: 'Benutzerkonto anlegen',
      login: 'Einloggen',
    },
  },
  managedFields: {
    title: 'Zusätzliche Information',
    crop_image: 'Bild zuschneiden',
    crop_again: 'Zurück zum Zuschneiden',
  },
  review: {
    title: 'Überprüfen Sie die Informationen',
    name: 'Name',
  },
  completed: {
    title: 'Aussteller erstellt!',
    subtitle: 'Ihr Aussteller ist bereit. Vielen Dank für Ihre Einreichung!',
    manage: 'Verwalten',
  },
  back: 'Zurück',
  next: 'Nächste',
  submit: 'Einreichen',
};

const sessions: IExhibitorSessions = {
  title: 'Sitzungen',
  subtitle: 'Sitzungen erstellen, bearbeiten und löschen',
  start_date: 'Beginnt um:',
  end_date: 'Endet um:',
  join_meeting: 'Sitzung beitreten',
  meeting_url: 'Meeting-URL:',
  description: 'Beschreibung:',
  new_session: 'Neue Sitzung',
  new_spacer: 'Neue Registerkarte',
  edit_session: 'Sitzung bearbeiten',
  edit_spacer: 'Trennzeichen bearbeiten',
  register: 'Registrieren',
  invited_users: 'Eingeladen',
  inputs: {
    name: 'Name',
    name_placeholder: 'Sitzungsnamen eingeben',
    start_date: 'Startdatum',
    start_date_placeholder: 'Startdatum für Sitzung eingeben',
    end_date: 'Enddatum',
    end_date_placeholder: 'Enddatum der Sitzung eingeben',
    color: 'Farbe Datum',
    color_placeholder: 'Farbe für Sitzung eingeben',
    description: 'Beschreibung',
    description_placeholder: 'Beschreibung der Sitzung eingeben',
    widget_block: 'Widget-Block',
    widget_block_placeholder: 'HTML-Code eingeben',
    widget_block_message:
      'Dieser Text oder html wird auf der Sitzungsseite unter dem Hauptvideo oder -bild und über der Beschreibung erscheinen.',
    entrance_capacity: 'Eintrittskapazität',
    entrance_capacity_placeholder: 'Eintrittskapazität eingeben',
    registration_capacity: 'Anmeldekapazität',
    registration_capacity_placeholder: 'Anmeldekapazität eingeben',
    external_link: 'Meeting-URL:',
    external_link_placeholder: 'Meeting-URL eingeben',
    external_link_warning_message:
      'Lassen Sie dieses Feld leer, wenn Sie möchten, dass beamian für Sie einen Meeting-Room erstellt.',
    order: 'Reihenfolge',
    order_placeholder: 'Reihenfolge für Sitzung eingeben',
    private_session: 'Private Sitzung',
    streaming_link: 'Streaming URL',
    streaming_link_placeholder: 'Enter streaming URL',
    streaming_link_warning_message:
      'This stream will be available for all users, including those not registered for this session.',
    image: 'Bild',
    image_placeholder: 'Ein Bild hochladen',
    hide: 'Verstecken',
    hide_on_calendar: 'Hide on programme',
    hide_remaining_capacity: 'Verbleibende Kapazität ausblenden',
    allow_exited_users_entering: 'Besucher können wieder eintreten',
    is_daily_checkin: 'Work as a daily checkin session',
    is_daily_checkin_help_text:
      'This session will be available for in-person access control in a specific date',
    use_default_language_only: 'Utilizar idioma padrão do evento',
    display_counter: 'Anzeige eines Zählers mit der Anzahl der Nutzer, die den Stream ansehen',
    display_counter_placeholder: 'Gilt nur für Amazon IVS-Streams.',
    location_image_placeholder: 'Ein Bild hochladen',
  },
  session_label: 'Sitzung',
  select_session: 'Sitzung auswählen',
  no_private_sessions: 'Es gibt keine Aussteller-Sitzungen',
  private_session_warning:
    'Erstellen Sie eine private Sitzung um bestimmte Besucher zu einem Meeting einzuladen. Wenn es eine öffentliche Sitzung ist, können alle Besucher die Sitzung einsehen und sich für sie anmelden.',
  enable_mod_chat: 'Moderations-Chat aktivieren',
  enable_chat: 'Chat aktivieren',
  embed_meeting: 'Besprechungsvideo mit einbeziehen',
  embed_meeting_detail:
    'Wenn Sie diese Option deaktivieren, wird der Meeting-Link in einem neuen Fenster geöffnet',
  you_have: 'You have',
  sessions_left: 'sessions left',
  new_stage: 'New Stage',
  add_stage: 'Add Stage',
  managers: 'Manager',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Besucher, die an Angeboten interessiert sind',
  subtitle: 'Die Liste der interessierten Besucher überprüfen und Meetings planen',
  product_name: 'Angebot',
  all_products: 'All products',
  interaction_name: 'Name des Besuchers',
  application: 'Kontaktinformation',
  invite_to_session: 'Meeting planen',
  send_invite: 'Einladung schicken',
  user_already_registered: 'Dieser Nutzer ist bereits für diese Sitzung angemeldet',
  publish_warning:
    'Indem Sie dieses Formular veröffentlichen, wird die Information für Besucher ersichtlich sein',
  invitation_sent: 'Einladung erfolgreich verschickt',
  search_placeholder: 'Geben Sie ein, wonach Sie in der Kontaktliste suchen möchten',
  empty_list_title: 'Ihre Produktinteraktionen werden hier angezeigt',
  empty_list_subtitle: 'Teilen Sie Ihre Ausstellerseite, um mehr Interaktionen zu erhalten!',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Ausstellerinformation',
  products: 'Angebote',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
