import axios from 'axios';
import { IEventV2 } from '@/models/event';
import {
  IAttendingFieldsEntity,
  IVisitor,
  IVisitorResponse,
  IAttendingAnswer,
  IFavoriteParams,
  IFavorite,
  IVisitorsParams,
  IVisitorContactsParams,
  IVisitorContact,
  IExportFile,
  IVisitorTypeChoice,
  IPreRegistration,
  ISessionManagerParams,
  IOrganizerVisitorContactsParams,
  IOrganizerVisitor,
  IVisitorType,
} from '@/models/visitor';
import { FieldsEntity } from '@/models/brand';
import { Resolve, IData, IParams } from '.';
import config from './config';
import { IFieldAnswer } from '@/models/form';

const url = process.env.VUE_APP_SERVER_PATH;

export interface IAttendeeLiveForm {
  sessions?: { field_type: string; value?: string }[];
  email: string;
  name: string;
  ticket_code?: string;
  addon_ids?: number[];
  bulk_code?: string;
  fields: IAttendingFieldsEntity[];
  pending?: boolean;
  awaiting_payment?: boolean;
  tag_id?: string;
  visitor_type?: number;
  ticket_type?: number;
  is_guest?: boolean;
}

export interface IGuestLiveForm {
  email: string;
  fake: boolean;
  name: string;
  short_id: string;
  tag_id: string;
}

export interface IPreRegistrationActioningForm {
  email: string;
  sessions?: { field_type: string; value?: string }[];
  ticket_code?: string;
  addon_ids?: number[];
  form_fields: IAttendingFieldsEntity[];
  awaiting_payment?: boolean;
  visitor_type?: number;
}

export interface IVisitorContactsExportsParams extends IParams {
  exhibitor?: number;
  event?: number;
}

export interface IVisitorRegistrationIntentParams {
  event: number;
  email: string;
  status: number;
}

export const addVisitorContactsExport = (exhibitor: number | undefined = undefined) =>
  new Promise((resolve: Resolve<IExportFile>) => {
    const requestUrl = `${url}/v2/visitor-contacts-exports`;

    const data = {
      exhibitor,
      source_type: 'visitor',
    };

    axios
      .post(requestUrl, data, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const deleteVisitorContactsExport = (exportId: number) =>
  new Promise((resolve: Resolve<IExportFile>) => {
    const requestUrl = `${url}/v2/visitor-contacts-exports/${exportId}`;

    axios
      .delete(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const getVisitorContactsExports = (params: IVisitorContactsExportsParams) =>
  new Promise((resolve: Resolve<IData<IExportFile>>) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    let requestUrl = `${url}/v2/visitor-contacts-exports?page_size=${params.page_size}`;

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.page) {
      requestUrl += `&page=${params.page}`;
    }
    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const getFavorites = (params: IFavoriteParams) =>
  new Promise((resolve: Resolve<IData<IFavorite>>) => {
    let requestUrl = `${url}/v2/visitor-favourites?page_size=100`;

    if (params.visitor) {
      requestUrl += `&visitor=${params.visitor}`;
    }
    if (params.source_type) {
      requestUrl += `&source_type=${params.source_type}`;
    }
    if (params.source_id) {
      requestUrl += `&source_id=${params.source_id}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const addFavorite = (params: IFavoriteParams) =>
  new Promise((resolve: Resolve<IFavorite>) => {
    axios
      .post(`${url}/v2/visitor-favourites`, params, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const deleteFavorite = (favoriteId: number) =>
  new Promise((resolve: Resolve<IFavorite>) => {
    axios
      .delete(`${url}/v2/visitor-favourites/${favoriteId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const newAttendee = (event: IEventV2, attendeeForm: IAttendeeLiveForm) =>
  new Promise((resolve: Resolve<IVisitorResponse>) => {
    axios
      .post(`${url}/event-live/${event.slug}/live_form`, attendeeForm, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const registerVisitor = (event: IEventV2, attendeeForm: IAttendeeLiveForm) =>
  new Promise((resolve: Resolve<IVisitorResponse>) => {
    axios
      .post(`${url}/event/${event.slug}/register_visitor`, attendeeForm, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const registerGuest = (event: IEventV2, attendeeForm: IGuestLiveForm) =>
  new Promise((resolve: Resolve<{ email_address: string }>) => {
    axios
      .post(`${url}/event/${event.slug}/register_guest`, attendeeForm, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const editAttendee = (event: IEventV2, attendeeForm: IAttendeeLiveForm) =>
  new Promise((resolve: Resolve<IVisitorResponse>) => {
    console.log(event, attendeeForm, resolve);
  });

export const getOrganizerVisitorContacts = (params: IOrganizerVisitorContactsParams) =>
  new Promise((resolve: Resolve<IOrganizerVisitor[]>) => {
    const requestUrl = `${url}/organizers/events/${
      params.event
    }/visitors?search=${encodeURIComponent(params.keyword)}&fields=id,email,name`;
    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getVisitorContacts = (params: IVisitorContactsParams) =>
  new Promise((resolve: Resolve<IData<IVisitorContact>>) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    if (!params.ordering) {
      params.ordering = '-id';
    }

    let requestUrl = `${url}/v2/visitor-contacts?page_size=${params.page_size}&page=${params.page}`;

    if (params.ordering) {
      requestUrl += `&ordering=${params.ordering}`;
    }

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.email) {
      requestUrl += `&email=${encodeURIComponent(params.email)}`;
    }

    if (params.phone_nr) {
      requestUrl += `&phone_nr=${params.phone_nr}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }
    if (params.favourite_status !== undefined) {
      requestUrl += `&favourite_status=${params.favourite_status}`;
    }
    if (params.sessions !== undefined) {
      requestUrl += `&sessions=${params.sessions}`;
    }
    if (params.interactions !== undefined) {
      requestUrl += `&interactions=${params.interactions}`;
    }
    if (params.product_interactions !== undefined) {
      requestUrl += `&product_interactions=${params.product_interactions}`;
    }
    if (params.exhibitor_invitations !== undefined) {
      requestUrl += `&exhibitor_invitations=${params.exhibitor_invitations}`;
    }
    if (params.bulk_codes !== undefined) {
      requestUrl += `&exhibitor_invitations=${params.bulk_codes}`;
    }
    if (params.overstaying !== undefined) {
      requestUrl += `&overstaying=${params.overstaying}`;
    }
    if (params.field !== undefined) {
      requestUrl += `&field=${JSON.stringify(params.field)}`;
    }
    if (params.pre_registered !== undefined) {
      requestUrl += `&pre_registered=${params.pre_registered}`;
    }
    if (params.managing_session !== undefined) {
      requestUrl += `&managing_session=${params.managing_session}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getVisitorTypes = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitor>>) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitor-type-choices?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&visitor_type__event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getEventVisitorTypes = (eventId: number) =>
  new Promise((resolve: Resolve<IData<IVisitorType>>) => {
    const requestUrl = `${url}/v2/visitor-types?event=${eventId}`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getPreRegistration = (preRegistrationToken: string) =>
  new Promise((resolve: Resolve<IPreRegistration>, reject) => {
    const requestUrl = `${url}/v2/pre-registrations/${preRegistrationToken}`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const actionPreRegistration = (
  preRegistrationToken: string,
  attendeeForm: IPreRegistrationActioningForm,
) =>
  new Promise((resolve: Resolve<IVisitorResponse>) => {
    axios
      .patch(`${url}/v2/pre-registrations/${preRegistrationToken}/action`, attendeeForm, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const assignSessionManager = (params: ISessionManagerParams) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .post(`${url}/v2/session-managers`, params, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const removeSessionManager = (manager_id: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .delete(`${url}/v2/session-managers/${manager_id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitors = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitor>>) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitors?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.email) {
      requestUrl += `&email=${encodeURIComponent(params.email)}`;
    }

    if (params.tag) {
      requestUrl += `&tag=${params.tag}`;
    }
    if (params.register_code) {
      requestUrl += `&register_code=${params.register_code}`;
    }
    if (params.phone_nr) {
      requestUrl += `&phone_nr=${params.phone_nr}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getVisitorTypeChoices = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitorTypeChoice>>) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitor-type-choices?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&visitor_type__event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const updateVisitorType = (visitor: number, visitorType: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${url}/v2/attending-visitor-types`, { visitor, visitor_type: visitorType }, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorById = (visitorId: number, legacy = false) =>
  new Promise((resolve: Resolve<IVisitor>) => {
    let requestUrl = `${url}/v2/visitors/${visitorId}`;

    if (legacy) {
      requestUrl += '?legacy=true';
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getVisitorContactById = (params: {
  visitor: number;
  exhibitor?: number;
  event?: number;
}) =>
  new Promise((resolve: Resolve<IVisitorContact>) => {
    let requestUrl = `${url}/v2/visitor-contacts/${params.visitor}`;
    if (params.exhibitor) {
      requestUrl += `?exhibitor=${params.exhibitor}`;
    }
    if (params.event) {
      requestUrl += `?event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getAttendingAnswers = (visitor: number) =>
  new Promise((resolve: Resolve<IData<IFieldAnswer>>) => {
    axios
      .get(`${url}/v2/attending-answers?visitor=${visitor}&page_size=100`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const updateEventAttendingAnswers = (
  event: number,
  visitor: number,
  fields: FieldsEntity[],
) =>
  new Promise((resolve: Resolve<IData<IAttendingAnswer>>) => {
    axios
      .post(
        `${url}/organizers/events/${event}/visitors/${visitor}/attending_answers`,
        { fields },
        config(),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const updateVisitorName = (event: number, visitor: number, data: { name: string }) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${url}/organizers/events/${event}/visitors/${visitor}/update_data`, data, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateVisitorShortId = (visitor: number, data: { event: number; short_id: string }) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`${url}/v1/attending/${visitor}`, data, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateEventAttendingAnswer = (attending_answer: any) =>
  new Promise((resolve: Resolve<IData<IAttendingAnswer>>) => {
    axios
      .patch(`${url}/v2/attending-answers/${attending_answer.id}`, attending_answer, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const success = (event: number, visitor: number) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${url}/organizers/events/${event}/visitors/${visitor}/confirm`, {}, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const pending = (event: number, visitor: number) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${url}/organizers/events/${event}/visitors/${visitor}/pending`, {}, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getLabelPdf = (event: number) =>
  new Promise((resolve: Resolve<{ url: string }>, reject) => {
    axios
      .get(`${url}/event-settings/${event}/default_label_pdf`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const importVisitors = (eventId: number, file: File) =>
  new Promise((resolve, reject) => {
    const requestUrl = `${url}/events/${eventId}/import_visitors_new`;

    const formData = new FormData();

    formData.append('file', file);

    axios
      .post(requestUrl, formData, config(true))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateRegistrationIntent = (params: IVisitorRegistrationIntentParams) =>
  new Promise((resolve, reject) => {
    const requestUrl = `${url}/v2/registration-intent/update-status`;

    axios
      .post(requestUrl, params, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const exportRegistrationIntents = (eventId: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    const requestUrl = `${url}/v2/registration-intent/xlsx_export?event=${eventId}`;

    axios
      .get(requestUrl, { ...config(), responseType: 'blob' })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const exportVisitors = (eventId: number, fields: string, discard: string) =>
  new Promise((resolve: Resolve<any>, reject) => {
    let requestUrl = `${url}/events/${eventId}/export_visitors_data_xls`;

    if (fields) {
      requestUrl = `${requestUrl}?_columns=${fields}`;
    }

    let divider = '&';
    if (discard) {
      if (!fields) {
        divider = '?';
      }
      requestUrl = `${requestUrl}${divider}_discard=${discard}`;
    }

    axios
      .get(requestUrl, { ...config(), responseType: 'blob' })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
