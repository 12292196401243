import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  events: 'Événements',
  exhibitors: 'Exposants',
  exhibitor: 'Exposant',
  edit: 'Éditer',
  create: 'Créer',
  delete: 'Supprimer',
  remove: 'Retirer',
  name: 'Nom',
  name_placeholder: 'Entrer votre nom',
  email: 'Adresse électronique',
  invalid_email: "Cet e-mail n'est pas valide",
  description: 'Description',
  category: 'Catégorie',
  model: 'Modèle',
  save: 'Sauvegarder',
  download: 'Télécharger',
  upload: 'Télécharger',
  change: 'Modifier',
  apply: 'Appliquer',
  empty_field_not_allowed: 'Ce champ doit être rempli',
  next: 'Suivant',
  previous: 'Précédent',
  close: 'Fermer',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  search: 'Rechercher',
  mandatory_field: 'Ce champ est obligatoire.',
  name_in_use: 'Nom déjà utilisé.',
  crop: 'Recadrer',
  published: 'Publié',
  not_published: 'Non publié',
  all: 'Tout',
  empty_list: 'Aucune donnée disponible',
  user_chat_failed: "Impossible d'ouvrir le chat avec cet utilisateur",
  activity_center: "Centre d'activités",
  no_recent_activity: 'Aucune activité récente',
  unread_messages: 'Messages non lus',
  reply: 'Répondre',
  session_invitations: 'Invitations en attente',
  accept: 'Accepter',
  refuse: 'Refuser',
  in_progress: 'En cours',
  recent_files: 'Fichiers récents',
  open_conversations: 'Ouvrir des conversations',
  all_fields_required: 'Tous les champs sont requis',
  something_went_wrong: 'Un problème est survenu',
  already_exists: 'Existe déjà',
  profile: 'Mon compte',
  filter_by_status: 'Filtrer par statut',
  open_meeting: 'Ouvrir une réunion',
  visitors: 'Visiteurs',
  saved: 'Enregistré',
  back: 'Retour',
  session_full: 'La séance est complète',
  file_not_image: "Ce fichier n'est pas une image",
  new_export: 'Nouvelle exportation',
  state: 'État',
  details: 'Détails',
  see_more: 'En savoir plus',
  manage: 'Gérer',
  see_as_visitor: 'Voir en tant que visiteur',
  see_as_exhibitor: "Voir en tant qu'exposant",
  manage_visitors: 'Gérer les visiteurs',
  manage_exhibitors: 'Gérer les exposants',
  manage_event: 'Gérer un événement',
  registered: 'inscrit',
  refused: 'refusé',
  pending: 'en attente',
  accepted: 'accepté',
  add_to_favorites: 'Ajouter aux favoris',
  remove_from_favorites: 'Supprimer des favoris',
  contact_request: 'Demande de contact',
  invited: 'Invité',
  note: 'Note',
  discard: 'Descarte',
  no_comment: 'Sin comentarios',
  click_here: 'Haga clic aquí',
  sessions: 'Séances',
  event_sessions: "Sessions d'événement",
  exhibitor_sessions: 'Séances exposants',
  networking: 'Réseautage',
  total: 'Total',
  update_success: 'Informations mises à jour avec succès',
  favorites: 'Favoris',
  agree_to_terms:
    'Accepter les <a href="https://beamian.com/legal-policies/" target="_blank"><b>termes et conditions de beamian</b></a>',
  add_to_calendar: 'Ajouter à votre calendrier',
  event_already_started: 'Cet événement a déjà commencé',
  countdown: 'Compte à rebours',
  please_check_answers: 'Veuillez vérifier vos réponses',
  sort_by: 'Trier par',
  order: 'Ordre',
  date: 'Date',
  ascending: 'Ascendant',
  descending: 'Descendant',
  no_data_title: 'Rien à voir ici!',
  no_data_subtitle: 'Revenez plus tard.',
  exhibitor_interactions: 'Interactions avec les exposants',
  interactions: 'Interactions',
  product_interactions: 'Interactions avec les produits',
  sessions_subtitle: 'Dernières données sur les numéros de session et les statistiques',
  online_registrations: 'Inscriptions en ligne',
  in_person_registrations: 'Inscriptions en personne',
  contacts: 'Contacts',
  created: 'Créé',
  seeing_as_visitor: 'Ver evento como visitante',
  total_sessions: 'Total de sessões',
};

export default common;
