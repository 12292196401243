import {
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorInteractionsTranslations,
  IExhibitorSettingsTranslations,
  IExhibitorSidebar,
  IExhibitorsDashboardTranslations,
  IExhibitorProducsTranslations,
  IExhibitorProductInteractions,
  IExhibitorProfile,
  IExhibitorTickets,
  IExhibitorVisitors,
  IExhibitorExports,
  IExhibitorTeam,
  IExhibitorsBookletTranslations,
} from '.';

const booklet: IExhibitorsBookletTranslations = {
  cta_copy: 'Exemplo de livro de visitas',
  success_message:
    'Será enviado para o seu correio eletrónico um livro de visita com todos os expositores.',
  no_booklet_message: 'Não existe um livro de visitas para ser enviado.',
  modal_title: 'Exemplo de livro de visitas',
  modal_subtitle: 'Um exemplo do livro de visitas será enviado para',
  send_booklet: 'Enviar',
};

const dashboard: IExhibitorsDashboardTranslations = {
  title: 'Olá',
  subtitle: 'Bem vindo/a à plataforma de gestão de eventos da beamian',
  ticket_codes_title: 'Códigos de bilhete',
  ticket_codes_subtitle: 'Copie e envie estes códigos a quem quiser convidar',
  usable_tickets: 'Bilhetes por usar',
  general_information_title: 'Indicadores de performance',
  general_information_subtitle: 'Como os visitantes estão a interagir consigo',
  sessions_subtitle: 'Últimos numeros de sessões criadas e registos',
  sessions_title: 'Your registration numbers',
  booked_as_favorite: 'Marcações como favorito',
  upcoming_sessions: 'Próximas sessões',
  exhibitor_pending_title: 'A aprovação do expositor está pendente',
  exhibitor_pending_text:
    'O seu expositor não foi aprovado para entrar na plataforma. Por favor espere até que o gestor do evento aprove o seu expositor.',
};

const exports: IExhibitorExports = {
  title: 'Exportações',
  subtitle: 'Gerir e descarregar suas exportações de contactos',
  export_contacts: 'Exportar contactos',
  export_contacts_subtitle: 'Selecionar as informações do visitante a incluir na exportação:',
};

const team: IExhibitorTeam = {
  title: 'Equipa',
  subtitle: 'Gerir os restantes gestores do expositor',
  title_empty: 'Os seus gestores do expositor aparecerão aqui!',
  subtitle_empty: 'Convide mais gestores deste expositor.',
  tab_1: 'Gestores do expositor',
  tab_2: 'Convites pendentes',
  exhibitor_mnanagers_left: 'gestores do expositor.',
  resend_registration_email: 'reenviar email de registo',
  resend_invitation: 'Reenviar convite',
  confirm_removing_exhibitor_manager: 'Deseja prosseguir e remover o gestor de expositor?',
  confirm_removing_exhibitor_manager_invite:
    'Deseja prosseguir e remover o convite para gestor de expositor?',
  email_input_title: 'Endereço de email',
  email_input_placeholder: 'johndoe@gmail.com',
  invite_exists: 'Já foi enviado um convite para este contacto.',
};

const visitors: IExhibitorVisitors = {
  title: 'Visitantes',
  subtitle: 'Ver informação de visitante, convidar para sessões privadas',
  assign_packages: 'Atribuir pacotes',
  visitor_information: 'Informação do visitante',
  assign_step_1: 'Encontra um visitante existente ou regista um novo',
  assign_step_2: 'Registar novo visitante',
  assign_step_3: 'Adicionar pacotes ao visitante',
  assign_step_4: 'Tudo feito!',
  assign_step_5: 'Não pode atribuir mais pacotes',
  out_of_packages: 'Sem pacotes para atribuir.',
  package_name: 'Nome do pacote',
  package_assigned_successfuly: 'Pacotes atribuidos com sucesso!',
  assign_another: 'Atribuir novamente',
  invite_private_session: 'Convidar para uma sessão privada',
  user_not_registered: 'Este visitante não está registado para nenhuma sessão com o seu expositor.',
  enter_note_placeholder: 'Adicione uma nota',
  empty_list_title: 'Os seus contactos aparecerão aqui',
  empty_list_subtitle: 'Partilhe a sua página de expositores para obter mais interacções!',
  filters: {
    registered_for_sessions: 'Registado para sessões',
    interacted_with_exhibitor: 'Interagiu com o expositor',
    interacted_with_product: 'Interagiu com ofertas',
    invited_by_you: 'Convidado por si',
    redeemed_code: 'Usou um dos seus códigos',
  },
  sessions: {
    export: 'Exportar',
    export_sessions: 'Exportar sessões',
    message_201:
      'Criação do ficheiro de exportação iniciada. Por favor, volte a tentar dentro de momentos.',
    message_204:
      'O ficheiro de exportação está a ser construído. Por favor, volte a tentar dentro de momentos.',
    subtitle: 'Selecione informação de visitante adicional para incluir na exportação:',
  },
};

const tickets: IExhibitorTickets = {
  title: 'Atribuições',
  subtitle: 'Bilhetes atribuidos a visitantes',
  new_assignment: 'Nova atribuição',
  assignee_name: 'Nome do recebedor/a',
  assignee_email: 'Email do recebedor/a',
  visitor_name: 'Nome do visitante',
  visitor_email: 'Email do visitante',
  ticket: 'Bilhete',
  ticket_type_title: 'Bilhetes',
  ticket_type_subtitle: 'Atribua bilhetes a visitantes',
  assign_title: 'Atribuir bilhete a visitante',
  assign: 'Atribuir',
  ticket_success: 'Bilhete atribuido com successo!',
};

const sidebar: IExhibitorSidebar = {
  title: 'Expositor',
  home: 'Home',
  interactions: 'Visitantes',
  exhibitor_interactions: 'Interessados no expositor',
  product_interactions: 'Interessados em ofertas',
  exhibitor_sessions: 'Sessões do expositor',
  sessions: 'Sessões',
  settings: 'Perfil',
  products: 'Ofertas',
  tickets: 'Bilhetes',
  exports: 'Exportações',
  team: 'Equipa',
};

const settings: IExhibitorSettingsTranslations = {
  title: 'Definições de expositor',
  basicInfo: 'Informação básica',
  save: 'Guardar',
  additionalInfo: 'Informação de registo',
  formError: 'Sem informação disponivel.',
};

const interactions: IExhibitorInteractionsTranslations = {
  title: 'Visitantes interessados no expositor',
  subtitle: 'Consultar lista de visitantes interessados no expositor',
  download: 'Descarregar',
  noInteractions: 'Este expositor não tem interações.',
  interaction: {
    editComment: 'Editar comentário',
    saveComment: 'Guardar comentário',
    checkIn: 'CheckIn',
  },
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
};

const products: IExhibitorProducsTranslations = {
  title: 'Ofertas',
  subtitle: 'Criar, editar e apagar ofertas',
  no_products: 'Nenhum pedido de informação disponível.',
  new_product: 'Nova oferta',
  create_new_product: 'Criar oferta',
  edit_product: 'Editar oferta',
  delete_confirm: 'Tem a certeza que pretende apagar esta oferta?',
  interactions_exist_cannot_delete:
    'Esta oferta já tem interações, por isso não é possível eliminar.',
  search_placeholder: 'Digite o que pretende procurar na lista de ofertas',
  you_have: 'Restam',
  products_left: 'ofertas.',
  inputs: {
    name: 'Nome',
  },
  form_not_ready:
    'O formulário do oferta ainda não foi criado pelo seu gestor de eventos. Por favor, tente novamente mais tarde.',
};

const onboarding: IExhibitorOnboardingTranslations = {
  step_authentication: 'Autenticação',
  step_basicInfo: 'Informação básica',
  step_additionalInfo: 'Informação de registo',
  step_review: 'Revisão',
  step_done: 'Terminado!',
  convert_titles: {
    logo: 'Logo',
    profile_pic: 'Imagem de perfil',
    embedded: 'Vídeo de Youtube',
    meeting_room: 'Ativar sala de reuniões virtual',
  },
  login: {
    title: 'Registar expositor',
    subtitle: 'Faça login ou crie uma conta beamian',
    message:
      'Para registar o seu expositor precisamos que inicie sessão ou se registe com uma conta Beamian.',
    exhibitor_manager_name: 'Introduza o nome do gestor de expositor',
    exhibitor_manager_email: 'Introduza o email do gestor de expositor',
  },
  basicInformation: {
    title: 'Dados do expositor',
    warning:
      'A informação providenciada será usada na criação de um booklet e no perfil acessível por visitantes deste evento.',
    createExhibitor: 'Criar expositor',
    exhibitor_name: 'Nome do expositor',
    exhibitor_name_placeholder: 'Digite o nome a ser atribuído ao seu stand',
    modal: {
      title: 'Aviso',
      subtitle: 'Se quiser gerir o seu expositor terá que iniciar sessão ou criar conta.',
      skip: 'Saltar',
      createAccount: 'Criar conta',
      login: 'Iniciar sessão',
    },
  },
  managedFields: {
    title: 'Informação de registo',
    crop_image: 'Cortar imagem',
    crop_again: 'Voltar a cortar',
  },
  review: {
    title: 'Rever informação',
    name: 'Nome',
  },
  completed: {
    title: 'Registo concluído',
    subtitle: 'Está tudo pronto. Obrigado pelo registo!',
    manage: 'Gerir',
  },
  back: 'Voltar',
  next: 'Seguinte',
  submit: 'Submeter',
};

const sessions: IExhibitorSessions = {
  title: 'Agenda',
  subtitle: 'Criar, alterar e eliminar sessões',
  start_date: 'Começa a:',
  end_date: 'Acaba a:',
  join_meeting: 'Entrar na sessão',
  meeting_url: 'URL da chamada:',
  description: 'Descrição:',
  new_session: 'Nova sessão',
  new_spacer: 'Novo separador',
  edit_session: 'Editar Sessão',
  edit_spacer: 'Editar separador',
  register: 'Registar na sessão',
  invited_users: 'Convidados',
  inputs: {
    name: 'Nome',
    name_placeholder: 'Digite o nome da sessão',
    start_date: 'Data de início',
    start_date_placeholder: 'Digite a data de início da sessão',
    end_date: 'Data de fim',
    end_date_placeholder: 'Digite a data do fim da sessão',
    color: 'Cor',
    color_placeholder: 'Escolha uma cor para a sessão',
    widget_block: 'Bloco de widgets',
    widget_block_placeholder: 'Digite o bloco de widgets',
    widget_block_message:
      'Este texto ou html aparecerá na página da sessão abaixo do vídeo ou imagem principal e acima da descrição.',
    description: 'Descrição',
    description_placeholder: 'Digite a descrição da sessão',
    entrance_capacity: 'Capacidade de entrada',
    entrance_capacity_placeholder: 'Digite a capacidade da sala',
    registration_capacity: 'Capacidade de registo',
    registration_capacity_placeholder: 'Digite o número máximo de registos',
    external_link: 'URL da chamada',
    external_link_placeholder: 'Digite o url da chamada/reunião',
    external_link_warning_message:
      'Mantenha este campo vazio se pretender que o beamian crie a sala de reuniões por si.',
    order: 'Ordem',
    order_placeholder: 'Digite a ordem da sessão',
    private_session: 'Sessão privada',
    streaming_link: 'URL da stream',
    streaming_link_placeholder: 'Digite o URL da stream',
    streaming_link_warning_message:
      'Esta stream estará disponivel para todos os utilizadores do evento, mesmo os não registados na sessão.',
    image: 'Imagem',
    image_placeholder: 'Carregue a imagem da sessão',
    hide: 'Esconder',
    hide_on_calendar: 'Esconder no calendário',
    hide_remaining_capacity: 'Esconder capacidade restante',
    allow_exited_users_entering: 'Os visitantes podem reentrar',
    is_daily_checkin: 'Funcionar como sessão de checkin diária',
    is_daily_checkin_help_text:
      'Esta sessão estará disponível para realizar o controlo de acessos no evento presencial numa data específica',
    use_default_language_only: 'Utilizar idioma padrão do evento',
    display_counter: 'Mostrar contador com o número de pessoas a assistir ao stream',
    display_counter_placeholder: 'Aplicável apenas a transmissões Amazon IVS.',
    location_image_placeholder: 'Carregue a imagem da localização',
  },
  session_label: 'Sessão',
  select_session: 'Seleccione a sessão',
  no_private_sessions: 'Não existem sessões privadas',
  private_session_warning:
    'Crie uma sessão privada para convidar visitantes específicos para uma reunião. Se for uma sessão pública, qualquer visitante poderá visualizar e registar-se na sua sessão.',
  enable_mod_chat: 'Ativar chat de moderação',
  enable_chat: 'Ativar chat',
  embed_meeting: 'Sessão interna',
  embed_meeting_detail: 'Se desativar esta opção o url da sessão será aberto numa nova aba',
  you_have: 'Restam',
  sessions_left: 'sessões',
  new_stage: 'Nova Stage',
  add_stage: 'Criar Stage',
  managers: 'Gestores',
};

const productInteractions: IExhibitorProductInteractions = {
  title: 'Interessados em ofertas',
  subtitle: 'Consultar a lista de visitantes interessados nas suas ofertas e agendar reuniões',
  product_name: 'Oferta',
  all_products: 'All products',
  interaction_name: 'Nome do visitante',
  application: 'Informação do contacto',
  invite_to_session: 'Agendar uma reunião',
  send_invite: 'Enviar convite',
  user_already_registered: 'Este utilizador já está convidado para esta sessão',
  publish_warning:
    'Ao publicar a oferta esta ficará visível para todos os visitantes deste evento.',
  invitation_sent: 'Convite enviado com sucesso',
  search_placeholder: 'Introduza o que pretende procurar na lista de contactos',
  empty_list_title: 'As interações com as suas ofertas aparecerão aqui',
  empty_list_subtitle: 'Partilhe a sua página de expositor para obter mais interacções',
};

const profile: IExhibitorProfile = {
  exhibitor_information: 'Informação do expositor',
  products: 'Ofertas',
};

export default {
  booklet,
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
  team,
};
