import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  events: 'Eventos',
  exhibitors: 'Expositores',
  exhibitor: 'Empresa',
  edit: 'Editar',
  remove: 'Quitar',
  create: 'Crear',
  delete: 'Eliminar',
  name: 'Nombre',
  name_placeholder: 'Introduzca su nombre',
  email: 'Email',
  invalid_email: 'Este correo electrónico no es válido',
  description: 'Descripción',
  category: 'Categoría',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descargar',
  upload: 'Subir',
  change: 'Cambiar',
  apply: 'Aplicar',
  empty_field_not_allowed: 'Este campo no puede estar vacío',
  next: 'Siguiente',
  previous: 'Anterior',
  close: 'Cerrar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Buscar',
  mandatory_field: 'Este campo es requerido.',
  name_in_use: 'Este nombre ya esta en uso.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'No publicado',
  all: 'Todas',
  empty_list: 'Datos no disponibles',
  user_chat_failed: 'No se pudo abrir el chat con esta usuario',
  activity_center: 'Centro de actividades',
  no_recent_activity: 'No hay actividad reciente',
  unread_messages: 'Mensajes no leídos',
  reply: 'Responder',
  session_invitations: 'Invitaciones sin respuesta',
  accept: 'Aceptar',
  refuse: 'Rechazar',
  in_progress: 'En progreso',
  recent_files: 'Archivos recientes',
  open_conversations: 'Conversaciones abiertas',
  all_fields_required: 'Todos los campos requeridos',
  something_went_wrong:
    'Algo ha ido mal. <br>Si el problema persiste, ponte en <a href="mailto:support@beamian.com">contacto con nosotros.</a>',
  already_exists: 'Ya existe',
  profile: 'Perfil',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar a la reunión',
  visitors: 'Visitante',
  saved: 'Guardado',
  back: 'Volver',
  session_full: 'La sesión está llena',
  file_not_image: 'Este archivo no es una imagen',
  new_export: 'Exportación nueva',
  state: 'Estado',
  details: 'Detalles',
  see_more: 'Ver más',
  manage: 'Gestionar',
  see_as_visitor: 'Ver como asistente',
  see_as_exhibitor: 'Ver como expositor',
  manage_visitors: 'Administrar visitante',
  manage_exhibitors: 'Administrar expositor',
  manage_event: 'Administrar evento',
  registered: 'registrado',
  refused: 'rechazado',
  pending: 'pendiente',
  accepted: 'acceptado',
  favorites: 'Favoritos',
  add_to_favorites: 'Agregar a los favoritos',
  remove_from_favorites: 'Quitar de favoritos',
  contact_request: 'Solicitud de contacto',
  invited: 'Invitado/a',
  note: 'Nota',
  discard: 'Descarte',
  no_comment: 'Sin comentario',
  click_here: 'Haga clic aquí',
  sessions: 'Sesiones',
  event_sessions: 'Sesiones de eventos',
  exhibitor_sessions: 'Sesiones de expositor',
  networking: 'Redes',
  total: 'Total',
  update_success: 'Información actualizada con éxito',
  agree_to_terms:
    'Acepto los <a href="https://beamian.es/politicas-legales/" target="_blank"><b>términos y condiciones de beamian</b></a>',
  add_to_calendar: 'Añadir a su calendario',
  event_already_started: 'Este evento ya ha comenzado',
  countdown: 'Cuenta atrás',
  please_check_answers: 'Por favor, compruebe sus respuestas',
  sort_by: 'Ordenar por',
  order: 'Orden',
  date: 'Fecha',
  ascending: 'Ascendente',
  descending: 'Descendente',
  no_data_title: 'No hay nada que ver aquí!',
  no_data_subtitle: 'Por favor, vuelva más tarde.',
  exhibitor_interactions: 'Interacciones del expositor',
  interactions: 'Aplicaciones espontáneas',
  product_interactions: 'Solicitud de empleo',
  sessions_subtitle: 'Últimos datos sobre números de sesiones y estadísticas',
  online_registrations: 'Registros para acceso en línea',
  in_person_registrations: 'Registros para acceso en persona',
  contacts: 'Contactos',
  created: 'Creado',
  seeing_as_visitor: 'Ver evento como visitante',
  total_sessions: 'Total de sesiones',
};

export default common;
