import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  events: 'Events',
  exhibitors: 'Candidates',
  exhibitor: 'Candidate',
  edit: 'Edit',
  discard: 'Discard',
  create: 'Create',
  delete: 'Delete',
  remove: 'Remove',
  name: 'Name',
  name_placeholder: 'Enter your name',
  email: 'Email',
  invalid_email: 'This email is invalid',
  description: 'Description',
  category: 'Category',
  model: 'Model',
  save: 'Save',
  download: 'Download',
  upload: 'Upload',
  change: 'Change',
  apply: 'Apply',
  empty_field_not_allowed: 'This field must not be empty',
  next: 'Next',
  previous: 'Previous',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  search: 'Search',
  mandatory_field: 'This field is mandatory.',
  name_in_use: 'Name already taken.',
  crop: 'Crop',
  published: 'Published',
  not_published: 'Unpublished',
  all: 'All',
  empty_list: 'No data available',
  user_chat_failed: 'Could not open chat with this user',
  activity_center: 'Activity center',
  no_recent_activity: 'No recent activity',
  unread_messages: 'Unread messages',
  reply: 'Reply',
  session_invitations: 'Pending invitations',
  accept: 'Accept',
  refuse: 'Refuse',
  in_progress: 'In progress',
  recent_files: 'Recent files',
  open_conversations: 'Open conversations',
  all_fields_required: 'All fields required',
  something_went_wrong: 'Something went wrong',
  already_exists: 'Already exists',
  profile: 'My account',
  filter_by_status: 'Filter by status',
  open_meeting: 'Open meeting',
  visitors: 'Visitors',
  saved: 'Saved',
  back: 'Back',
  session_full: 'Full',
  file_not_image: 'This file is not an image',
  new_export: 'New export',
  state: 'State',
  details: 'Details',
  see_more: 'See more',
  manage: 'Manage',
  see_as_visitor: 'See as visitor',
  see_as_exhibitor: 'See as exhibitor',
  manage_visitors: 'Manage candidates',
  manage_exhibitors: 'Manage exhibitors',
  manage_event: 'Manage event',
  registered: 'registered',
  refused: 'refused',
  pending: 'pending',
  accepted: 'accepted',
  favorites: 'Favorites',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  contact_request: 'Contact request',
  invited: 'Invited',
  note: 'Note',
  no_comment: 'No comment',
  click_here: 'Click here',
  sessions: 'Sessions',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  networking: 'Networking',
  total: 'Total',
  update_success: 'Information successfuly updated',
  agree_to_terms:
    'Agree to <a href="https://beamian.com/legal-policies/" target="_blank"><b>beamian terms and conditions</b></a>',
  add_to_calendar: 'Add to your calendar',
  event_already_started: 'This event has already started',
  countdown: 'Countdown',
  please_check_answers: 'Please check your answers',
  sort_by: 'Sort by',
  order: 'Order',
  date: 'Date',
  ascending: 'Ascending',
  descending: 'Descending',
  no_data_title: 'Nothing to see here!',
  no_data_subtitle: 'Please come back later.',
  exhibitor_interactions: 'Exhibitor interactions',
  interactions: 'Interactions',
  product_interactions: 'Product interactions',
  sessions_subtitle: 'Latest data on session numbers and statistics',
  online_registrations: 'Online access registrations',
  in_person_registrations: 'In-person access registrations',
  contacts: 'Contacts',
  created: 'Created',
  seeing_as_visitor: 'Seeing event as visitor',
  total_sessions: 'Total sessions',
};
export default common;
