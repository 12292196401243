import { ICommonTranslations } from './index';

const common: ICommonTranslations = {
  events: 'Veranstaltungen',
  exhibitors: 'Aussteller',
  exhibitor: 'Aussteller',
  remove: 'Entfernen',
  edit: 'Bearbeiten',
  add_spacer: 'Trennzeichen hinzufügen',
  add_stage: 'Add Stage',
  stages_message: 'These will be available in your session forms',
  edit_checkin: 'Bearbeiten check-in',
  create: 'Erstellen',
  delete: 'Löschen',
  name: 'Name',
  first_last_name: 'Vorname und Nachname',
  name_placeholder: 'Geben Sie Ihren Namen ein',
  email: 'E-Mail',
  invalid_email: 'Diese E-Mail ist ungültig',
  email_already_associated:
    'Die eingegebene E-Mail ist bereits mit einem anderen Abonnement verknüpft',
  description: 'Beschreibung',
  category: 'Kategorie',
  categories: 'Kategorien',
  model: 'Modell',
  save: 'Speichern',
  items: 'Artikel',
  download: 'Herunterladen',
  upload: 'Hochladen',
  change: 'Veränderung',
  apply: 'Anwenden',
  empty_field_not_allowed: 'Dieses Feld darf nicht leer sein.',
  next: 'Nächste',
  previous: 'Bisherige',
  close: 'Schließen',
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  search: 'Suche',
  mandatory_field: 'Dieses Feld ist verpflichtend.',
  name_in_use: 'Name bereits vergeben.',
  crop: 'Zuschneiden',
  published: 'Veröffentlicht',
  not_published: 'Unveröffentlicht',
  all: 'Alle',
  empty_list: 'Keine Daten verfügbar',
  user_chat_failed: 'Konnte Chat mit diesem User nicht öffnen',
  activity_center: 'Activity-Center',
  no_recent_activity: 'Keine aktuelle Aktivität',
  unread_messages: 'Ungelesene Nachrichten',
  reply: 'Antworten',
  session_invitations: 'Unbeantwortete Einladungen',
  accept: 'Akzeptieren',
  refuse: 'Ablehnen',
  in_progress: 'In Bearbeitung',
  recent_files: 'Letzte Dateien',
  open_conversations: 'Offene Konversationen',
  all_fields_required: 'Alle Felder erforderlich',
  something_went_wrong: 'Etwas ist schiefgelaufen',
  already_exists: 'Existiert bereits',
  profile: 'Mein Konto',
  filter_by_status: 'Nach Status filtern',
  open_meeting: 'Offenes Meeting ',
  visitors: 'Besucher',
  saved: 'Gespeichert',
  back: 'Zurück',
  session_full: 'Sitzung ist voll',
  file_not_image: 'Diese Datei ist kein Bild',
  new_export: 'Neuer Export',
  state: 'Status',
  details: 'Details',
  see_more: 'Mehr sehen',
  more: 'Mehr',
  manage: 'Verwalten',
  see_as_visitor: 'Als Besucher ansehen',
  see_as_exhibitor: 'Als Aussteller ansehen',
  manage_visitors: 'Besucher verwalten',
  manage_exhibitors: 'Aussteller verwalten',
  manage_event: 'Veranstaltung verwalten',
  registered: 'registriert',
  refused: 'abgelehnt',
  pending: 'ausstehend',
  session_manager: 'Session manager',
  export_questions: 'Fragen exportieren',
  my_mod_questions: 'Letzte Fragen',
  mod_questions: 'Fragen zur Moderation',
  pending_plural: 'Ausstehend',
  read: 'Gelesen',
  mark_as_read: 'Als gelesen markieren',
  accepted: 'akzeptiert',
  favorites: 'Favoriten',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  contact_request: 'Contact request',
  invited: 'Invited',
  note: 'Note',
  discard: 'Verwerfen',
  no_comment: 'Kein Kommentar',
  click_here: 'Hier klicken',
  sessions: 'Sitzungen',
  event_sessions: 'Ereignissitzungen',
  exhibitor_sessions: 'Ausstellersitzungen',
  networking: 'Vernetzung',
  total: 'Gesamt',
  update_success: 'Informationen erfolgreich aktualisiert',
  agree_to_terms:
    'Stimmen Sie <a href="https://beamian.com/legal-policies/" target="_blank"><b>beamian Geschäftsbedingungen zu</b></a>',
  add_to_calendar: 'Zu Ihrem Kalender hinzufügen',
  event_already_started: 'Dieses Ereignis hat bereits begonnen',
  countdown: 'Countdown',
  please_check_answers: 'Bitte überprüfen Sie Ihre Antworten',
  sort_by: 'Sortieren nach',
  order: 'Reihenfolge',
  date: 'Datum',
  ascending: 'Aufsteigend',
  descending: 'Absteigend',
  no_data_title: 'Hier gibt es nichts zu sehen!',
  no_data_subtitle: 'Bitte kommen Sie später wieder.',
  exhibitor_interactions: 'Aussteller-Interaktionen',
  interactions: 'Interaktionen',
  product_interactions: 'Produktinteraktionen',
  sessions_subtitle: 'Neueste Daten zu Sitzungszahlen und Statistiken',
  online_registrations: 'Online-Registrierungen',
  in_person_registrations: 'Persönliche Registrierungen',
  contacts: 'Kontakte',
  created: 'Erstellt',
  seeing_as_visitor: 'Ereignis als Besucher sehen',
  total_sessions: 'Sitzungen insgesamt',
  ticket_types: 'Ticket type',
  image: 'Image',
  add: 'Add',
  tickets: 'Tickets',
  sold: 'Sold',
  price: 'Price',
  select_exhibitor: 'Select exhibitor',
  code: 'Code',
  type: 'Type',
  discount: 'Discount',
  status: 'Status',
  from: 'From',
  to: 'To',
  filter: 'Filter',
  session_privacy: 'Session privacy',
  option: 'Option',
  private: 'Private',
  public: 'Public',
  my_addons: 'My add-ons',
  see_addons_bought: 'See add-ons/extras you bought',
  form: 'Form',
  checkout: 'Checkout',
  product_status: 'Status',
  price_includes_tax: 'Price includes tax',
  filter_visitor_contacts: 'Besucherkontakte filtern',
  filter_networking_contacts: 'Filter für Netzwerkkontakte',
  filter_exhibitors: 'Aussteller filtern',
  check_sources: 'Nach Herkunft der Interaktion und Antworten der Besucher filtern',
  filter_visitor_multiple_select:
    'Durch Auswahl mehrerer Elemente werden die Kontakte nach allen ausgewählten Optionen gefiltert.',
  content_not_allowed: 'You are not allowed to access this content.',
  fill_payment_information: 'Inform taxpayer number',
  only_letters_numbers_hyphen_underscore:
    'Only letters, numbers, hyphen and underscore are allowed',
  registration_closed: 'The accreditation for :event_name is now closed',
  return_home: 'Go back home',
  character_limit_1: 'limited to',
  character_limit_2: 'characters',
  booklet_sample: 'Booklet sample',
  invite_managers: 'Manager einladen/entfernen',
  scan_for_interactions: 'Nach Interaktionen suchen',
  invite_managers_cta: 'Manager einladen',
  grant_permissions: 'Verwaltungsberechtigungen',
  grant_permissions_error: 'Fehler beim Ändern von Berechtigungen',
  invitation_send_error: 'Fehler beim Senden einer Einladung',
  permissions_changed: 'Berechtigungen erfolgreich aktualisiert',
  pre_registered: 'Vorregistrierung',
  evaluate_session: 'Sitzung auswerten',
  session_evaluation_title: 'Sitzungsbewertung',
  confirm_vote: 'Bist du sicher, dass du deine Stimme bestätigen willst?',
  vote_already_submitted: 'Ihre Antwort auf diese Frage wurde bereits eingereicht',
  vote_cannot_be_update: 'Ihre Stimme kann nach Abgabe nicht aktualisiert werden',
  evaluate_event: 'Ereignis bewerten',
  event_evaluation_title: 'Veranstaltungsauswertung',
  sold_out: 'Ausverkauft',
  exhibitors_1347: 'Speakers',
  clear_selection: 'Eindeutige Auswahl',
  ticket_label: 'Ticket-Etikett',
  ticket_purchase_info: 'Infos zum Ticketkauf',
};

export default common;
